import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSubheader } from 'vuetify/lib/components/VSubheader';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{staticClass:"d-flex flex-column",attrs:{"flat":"","tile":"","min-height":"380"}},[_c('confirm',{ref:"confirm"}),(!_vm.path)?_c(VCardText,{staticClass:"grow d-flex justify-center align-center grey--text"},[_vm._v("Select a folder or a file ")]):(_vm.isFile)?_c(VCardText,{staticClass:"grow d-flex justify-center align-center"},[_vm._v("File: "+_vm._s(_vm.path)+" ")]):(_vm.dirs.length || _vm.files.length)?_c(VCardText,{staticClass:"grow"},[(_vm.dirs.length)?_c(VList,{attrs:{"subheader":""}},[_c(VSubheader,[_vm._v("Folders")]),_vm._l((_vm.dirs),function(item){return _c(VListItem,{key:item.basename,staticClass:"pl-0",on:{"click":function($event){return _vm.changePath(item.path)}}},[_c(VListItemAvatar,{staticClass:"ma-0"},[_c(VIcon,[_vm._v("mdi-folder-outline")])],1),_c(VListItemContent,{staticClass:"py-2"},[_c(VListItemTitle,{domProps:{"textContent":_vm._s(item.basename)}})],1),_c(VListItemAction,[(false)?_c(VBtn,{attrs:{"icon":""}},[_c(VIcon,{attrs:{"color":"grey lighten-1"}},[_vm._v("mdi-information")])],1):_vm._e()],1)],1)})],2):_vm._e(),(_vm.dirs.length && _vm.files.length)?_c(VDivider):_vm._e(),(_vm.files.length)?_c(VList,{attrs:{"subheader":""}},[_c(VSubheader,[_vm._v("Files")]),_vm._l((_vm.files),function(item){return _c(VListItem,{key:item.basename,staticClass:"pl-0"},[_c(VListItemAvatar,{staticClass:"ma-0"},[_c(VIcon,[_vm._v(_vm._s(_vm.icons[item.extension.toLowerCase()] || _vm.icons['other']))])],1),_c(VListItemContent,{staticClass:"py-2"},[_c(VListItemTitle,{domProps:{"textContent":_vm._s(item.basename)}}),_c(VListItemSubtitle,[_vm._v(_vm._s(_vm.formatBytes(item.size)))])],1),_c(VListItemAction,[(false)?_c(VBtn,{attrs:{"icon":""}},[_c(VIcon,{attrs:{"color":"grey lighten-1"}},[_vm._v("mdi-information")])],1):_vm._e(),_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){return _vm.download(item.key)}}},[_c(VIcon,[_vm._v("mdi-download")])],1)],1)],1)})],2):_vm._e()],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }