<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="4" md="3">
        <v-sheet rounded="lg" class="surface">
          <h1 class="text-center">Groups</h1>
          <v-divider class="my-2"/>
          <v-list color="surface">
            <v-list-item-group v-model="selectedGroup" mandatory>
              <v-list-item
                  v-for="(n) in groupMembership"
                  :key="n._id" @change="changeGroup(n)" @input="changeGroup(n)"
              >
                <v-list-item-icon>
                  <v-icon>mdi-domain</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ getGroupName(n._id,'firstUse') }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-sheet>
      </v-col>
      <v-col cols="12" sm="8" md="9">
    <FileExplorerBase :group="activeGroup" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import FileExplorerBase from "@/components/fileManager/FileManagerBase";
import {mapState} from "vuex";
import {getGroupName} from "@/util/helpers";
export default {
  name: "FileExplorerPage",
  components: {FileExplorerBase},
  computed: {
    ...mapState("app", ["loading","loggedInUser"]),
    ...mapState("group", ["groups"]),
    ...mapState("user", ["groupMembership"]),
  },
  data() {
    return {
      selectedGroup:0,
      activeGroup:null,
      activeGroupName:null,
      accessToken:null
    }
  },
  methods:{
    getGroupName(id,style){
      return getGroupName(this.groups,id,style)
    },
    async changeGroup(group) {
      const groupDetails = this.groups.find(g => g._id == group._id)
      this.activeGroup = groupDetails._id

    },
  },
  async mounted() {
    if (this.groupMembership) {
      await this.changeGroup(this.groupMembership[0])
    }
  }
}
</script>

<style scoped>

</style>