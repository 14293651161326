<template>
  <v-card flat tile min-height="380" class="d-flex flex-column">
    <confirm ref="confirm"></confirm>
<!--    <file-manager-download :objectKey="downloadObjectKey"/>-->
    <v-card-text
        v-if="!path"
        class="grow d-flex justify-center align-center grey--text"
    >Select a folder or a file
    </v-card-text>
    <v-card-text
        v-else-if="isFile"
        class="grow d-flex justify-center align-center"
    >File: {{ path }}
    </v-card-text>
    <v-card-text v-else-if="dirs.length || files.length" class="grow">
      <v-list subheader v-if="dirs.length">
        <v-subheader>Folders</v-subheader>
        <v-list-item
            v-for="item in dirs"
            :key="item.basename"
            @click="changePath(item.path)"
            class="pl-0"
        >
          <v-list-item-avatar class="ma-0">
            <v-icon>mdi-folder-outline</v-icon>
          </v-list-item-avatar>
          <v-list-item-content class="py-2">
            <v-list-item-title v-text="item.basename"></v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
<!--            <v-btn icon @click.stop="deleteItem(item,path)">-->
<!--              <v-icon color="grey lighten-1">mdi-delete-outline</v-icon>-->
<!--            </v-btn>-->
            <v-btn icon v-if="false">
              <v-icon color="grey lighten-1">mdi-information</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>
      <v-divider v-if="dirs.length && files.length"></v-divider>
      <v-list subheader v-if="files.length">
        <v-subheader>Files</v-subheader>
        <v-list-item
            v-for="item in files"
            :key="item.basename"
            class="pl-0">
          <!--            @click="changePath(item.path)"-->
          <v-list-item-avatar class="ma-0">
            <v-icon>{{ icons[item.extension.toLowerCase()] || icons['other'] }}</v-icon>
          </v-list-item-avatar>

          <v-list-item-content class="py-2">
            <v-list-item-title v-text="item.basename"></v-list-item-title>
            <v-list-item-subtitle>{{ formatBytes(item.size) }}</v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-action>
<!--            <v-btn icon @click.stop="deleteItem(item)">-->
<!--              <v-icon color="grey lighten-1">mdi-delete-outline</v-icon>-->
<!--            </v-btn>-->
            <v-btn icon v-if="false">
              <v-icon color="grey lighten-1">mdi-information</v-icon>
            </v-btn>
              <v-btn icon @click="download(item.key)">
                <v-icon>mdi-download</v-icon>
              </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-card-text>
<!--    <v-card-text-->
<!--        v-else-if="filter"-->
<!--        class="grow d-flex justify-center align-center grey&#45;&#45;text py-5"-->
<!--    >No files or folders found-->
<!--    </v-card-text>-->
<!--    <v-card-text-->
<!--        v-else-->
<!--        class="grow d-flex justify-center align-center grey&#45;&#45;text py-5"-->
<!--    >The folder is empty-->
<!--    </v-card-text>-->
<!--    <v-divider v-if="path"></v-divider>-->
<!--    <v-toolbar v-if="false && path && isFile" dense flat class="shrink">-->
<!--      <v-btn icon>-->
<!--        <v-icon>mdi-download</v-icon>-->
<!--      </v-btn>-->
<!--    </v-toolbar>-->
<!--    <v-toolbar v-if="path && isDir" dense flat class="shrink">-->
<!--      <v-text-field-->
<!--          solo-->
<!--          flat-->
<!--          hide-details-->
<!--          label="Filter"-->
<!--          v-model="filter"-->
<!--          prepend-inner-icon="mdi-filter-outline"-->
<!--          class="ml-n3"-->
<!--      ></v-text-field>-->
<!--      <v-btn icon v-if="false">-->
<!--        <v-icon>mdi-eye-settings-outline</v-icon>-->
<!--      </v-btn>-->
<!--      <v-btn icon @click="load">-->
<!--        <v-icon>mdi-refresh</v-icon>-->
<!--      </v-btn>-->
<!--    </v-toolbar>-->
  </v-card>
</template>

<script>
import {formatBytes} from "@/util/helpers";
// import {formatBytes, getFileExtension, removeLeadingSlash, removeTrailingSlash} from "@/util/helpers";
// import {StorageHttp} from "@/http/StorageHttp";
import Confirm from "@/components/fileManager/Confirm";
import FileManagerDownload from "@/components/fileManager/FileManagerDownload";

export default {
  name: "FileManagerContentPane",
  props: {
    icons: Object,
    storage: String,
    path: String,
    refreshPending: Boolean,
    files:Array,
    dirs:Array,
  },
  components: {
    Confirm
  },
  data() {
    return {
      filter: "",
      componentKey: 0,
      downloadObjectKey:null
    };
  },
  computed: {
    // dirs() {
    //   return this.items.filter(
    //       item =>
    //           item.type === "dir" && item.basename.includes(this.filter)
    //   );
    // },
    filteredFiles() {
      return this.files.filter(
          item => item.size =0
      );
    },
    isDir() {
      return this.path[this.path.length - 1] === "/";
    },
    isFile() {
      return !this.isDir;
    }
  },
  methods: {
    formatBytes,
    forceRerender() {
      this.componentKey += 1;
    },
    changePath(path) {
      this.$emit("path-changed", path);
    },
    download(item){
      this.$emit("download-file", item);
      this.downloadObjectKey = item
    },
    // findItem(path) {
    //   let stack = [];
    //   stack.push(this.items[0]);
    //   while (stack.length > 0) {
    //     let node = stack.pop();
    //     if (node.path === path) {
    //       return node;
    //     } else if (node.children && node.children.length) {
    //       for (let i = 0; i < node.children.length; i++) {
    //         stack.push(node.children[i]);
    //       }
    //     }
    //   }
    //   return null;
    // },
    // async deleteItem(item) {
    //   let confirmed = await this.$refs.confirm.open(
    //       "Delete",
    //       `Are you sure<br>you want to delete this ${
    //           item.type === "dir" ? "folder" : "file"
    //       }?<br><em>${item.basename}</em>`
    //   );
    //   if (confirmed) {
    //     this.$emit("loading", true);
    //     await StorageHttp.delete('s3', `${item.key}`);
    //     this.$emit("file-deleted");
    //     this.$emit("loading", false);
    //   }
    // }
  },
  watch: {
    // async path() {
    //   this.items = [];
    //   await this.load();
    // },
    // async refreshPending() {
    //   if (this.refreshPending) {
    //     this.items = [];
    //     await this.load();
    //     this.$emit("refreshed");
    //   }
    // }
  }
};
</script>

<style lang="scss" scoped>
.v-card {
  height: 100%;
}
</style>