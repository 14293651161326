import { VAlert } from 'vuetify/lib/components/VAlert';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,[_c(VRow,[_c(VAlert,{attrs:{"type":"info"}},[_c('div',{staticClass:"text-h6"},[_vm._v("Experimental Feature")]),_c('div',{staticClass:"text-body-2 my-2"},[_vm._v("This is an experimental feature and is subject to change. It has been released early to provide an alternative to the current document library built on the Dropbox API. The functionality and behaviour within this section is not reflective of a production feature. ")]),_c('div',{staticClass:"text-body-2"},[_vm._v("To download a file, use the icon to the right of the file. The file will try to open in a new Browser window. Popup blockers may inhibit the download of the file as well as any corporate proxy servers or firewalls. ")])])],1),_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c('file-manager-toolbar',{attrs:{"path":_vm.path,"storages":_vm.storagesArray,"storage":_vm.activeStorage},on:{"storage-changed":_vm.storageChanged,"path-changed":_vm.pathChanged,"add-files":_vm.addUploadingFiles,"folder-created":function($event){_vm.refreshPending = true}}})],1)],1),_c(VRow,{attrs:{"no-gutters":""}},[(_vm.tree && _vm.$vuetify.breakpoint.smAndUp)?_c(VCol,{attrs:{"sm":"auto"}}):_vm._e(),(_vm.tree)?_c(VDivider,{attrs:{"vertical":""}}):_vm._e(),_c(VCol,[_c('file-manager-content-pane',{attrs:{"files":_vm.filesArray,"dirs":_vm.foldersArray,"path":_vm.path,"storage":_vm.activeStorage,"icons":_vm.icons,"refreshPending":_vm.refreshPending},on:{"download-file":function($event){return _vm.download($event)},"path-changed":function($event){return _vm.pathChanged($event)},"loading":_vm.loadingChanged,"refreshed":function($event){_vm.refreshPending = false},"file-deleted":function($event){_vm.refreshPending = true}}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }