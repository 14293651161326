export class StorageHttp {
    //AWS s3
    static async mkDir(storage) {
        try {
            return await window.axios.post(`storage/s3/v3/mkdir`, storage)
        } catch (err) {
            return Promise.reject(err);
        }
    }

    static async list(storage) {
        try {
            // console.log(`storage/s3/v3/list/${encodeURIComponent(storage.path)}`)
            return await window.axios.get(`storage/s3/v3/${encodeURIComponent(storage.majorFolder)}/${encodeURIComponent(storage.path)}`)

        } catch (err) {
            return Promise.reject(err);
        }
    }

    static async download(storage) {
        try {
            // console.log(`storage/s3/v3/list/${encodeURIComponent(storage.path)}`)
            return await window.axios.post(`storage/s3/v3/download`, storage)

        } catch (err) {
            return Promise.reject(err);
        }
    }

    static async upload(storage) {
        try {
            return await window.axios.post(`storage/s3/v3/upload`, storage)
        } catch (err) {
            return Promise.reject(err);
        }
    }



    static async delete(storage,key) {
        try {
            console.log(storage)
            console.log(key)
            return await window.axios.delete(`storage/s3/v3/delete/${encodeURIComponent(key)}`)
        } catch (err) {
            return Promise.reject(err);
        }
    }


}