<template>
  <v-dialog
      transition="dialog-top-transition"
      max-width="600"
      :value="true"
  >
    <template v-slot:default="dialog">
      <v-card>
        <v-toolbar
            color="primary"
            dark
        >Opening from the top
        </v-toolbar>
        <v-card-text>
          <v-progress-circular
              indeterminate
              color="primary"
              v-if="!url"
          ></v-progress-circular>
          <div class="text-h2 pa-12">Hello world!</div>
          {{objectKey}}
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
              text
              @click="dialog.value = false"
          >Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
export default {
  name: "FileManagerDownload",
  props: {
    objectKey: {
      type: String || null,
      default: null
    }
  },
  data() {
    return {
      url: ""
    }
  },
  watch: {
    objectKey(newKey) {
      this.url= null
      console.info(`The object to download is ${newKey}`)
      this.url = this.getPresignedURL(newKey)
    }
  },
  methods: {
    async getPresignedURL(key) {
      console.log(key)

      return 'test'

    },
  }
}
</script>

<style scoped>

</style>