<template>
  <v-container>
    <v-row>
      <v-alert type="info">
        <div class="text-h6">Experimental Feature</div>
        <div class="text-body-2 my-2">This is an experimental feature and is subject to change. It has been released
          early to provide an alternative to the current document library built on the Dropbox API. The functionality
          and behaviour within this section is not reflective of a production feature.
        </div>
        <div class="text-body-2">To download a file, use the icon to the right of the file. The file will try to open in
          a new Browser window. Popup blockers may inhibit the download of the file as well as any corporate proxy
          servers or firewalls.
        </div>

      </v-alert>
    </v-row>
    <v-row>
      <v-col cols="12">
        <file-manager-toolbar
            :path="path"
            :storages="storagesArray"
            :storage="activeStorage"
            v-on:storage-changed="storageChanged"
            v-on:path-changed="pathChanged"
            v-on:add-files="addUploadingFiles"
            v-on:folder-created="refreshPending = true"
        />
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col v-if="tree && $vuetify.breakpoint.smAndUp" sm="auto">
        <!--        <file-manager-navigation-pane-->
        <!--            :items="foldersArray"-->
        <!--            :path="path"-->
        <!--            storage="s3"-->
        <!--            :icons="icons"-->
        <!--            :refreshPending="refreshPending"-->
        <!--            v-on:path-changed="pathChanged"-->
        <!--            v-on:loading="loadingChanged"-->
        <!--            v-on:refreshed="refreshPending = false"/>-->
      </v-col>
      <v-divider v-if="tree" vertical></v-divider>
      <v-col>
        <file-manager-content-pane
            :files="filesArray"
            :dirs="foldersArray"
            :path="path"
            :storage="activeStorage"
            :icons="icons"
            :refreshPending="refreshPending"
            v-on:download-file="download($event)"
            v-on:path-changed="pathChanged($event)"
            v-on:loading="loadingChanged"
            v-on:refreshed="refreshPending = false"
            v-on:file-deleted="refreshPending = true"
        />
      </v-col>
    </v-row>
    <!--    <file-manager-upload-->
    <!--        v-if="uploadingFiles !== false"-->
    <!--        :path="path"-->
    <!--        :storage="activeStorage"-->
    <!--        :files="uploadingFiles"-->
    <!--        :icons="icons"-->
    <!--        :maxUploadFilesCount="maxUploadFilesCount"-->
    <!--        :maxUploadFileSize="maxUploadFileSize"-->
    <!--        v-on:add-files="addUploadingFiles"-->
    <!--        v-on:remove-file="removeUploadingFile"-->
    <!--        v-on:clear-files="uploadingFiles = []"-->
    <!--        v-on:cancel="uploadingFiles = false"-->
    <!--        v-on:uploaded="uploaded"-->
    <!--    ></file-manager-upload>-->
  </v-container>
</template>

<script>
// import FileManagerNavigationPane from "@/components/fileManager/FileManagerNavigationPane";
import {StorageHttp} from "@/http/StorageHttp";
import {getFileExtension, removeLeadingSlash, removeTrailingSlash} from "@/util/helpers";
import FileManagerContentPane from "@/components/fileManager/FileManagerContentPane";
import FileManagerToolbar from "@/components/fileManager/FileManagerToolbar";

const availableStorages = [
  // {
  //   name: "Local",
  //   code: "local",
  //   icon: "mdi-folder-multiple-outline"
  // },
  {
    name: "Amazon S3",
    code: "s3",
    icon: "mdi-aws"
  }
  /*{
      name: "Dropbox",
      code: "dropbox",
      icon: "mdi-dropbox"
  }*/
];

const fileIcons = {
  zip: "mdi-folder-zip-outline",
  rar: "mdi-folder-zip-outline",
  htm: "mdi-language-html5",
  html: "mdi-language-html5",
  js: "mdi-nodejs",
  json: "mdi-json",
  md: "mdi-markdown",
  pdf: "mdi-file-pdf",
  png: "mdi-file-image",
  jpg: "mdi-file-image",
  jpeg: "mdi-file-image",
  mp4: "mdi-filmstrip",
  mkv: "mdi-filmstrip",
  avi: "mdi-filmstrip",
  wmv: "mdi-filmstrip",
  mov: "mdi-filmstrip",
  txt: "mdi-file-document-outline",
  xls: "mdi-file-excel",
  xlsx: "mdi-file-excel",
  xlsm: "mdi-file-excel",
  other: "mdi-file-outline"
};

export default {
  name: "FileManagerBase",
  components: {FileManagerToolbar, FileManagerContentPane},
  props: {
    // comma-separated list of active storage codes
    group: {type: String},
    storages: {
      type: String,
      default: () => availableStorages.map(item => item.code).join(",")
    },
    // code of default storage
    storage: {type: String, default: "s3"},
    // show tree view
    tree: {type: Boolean, default: true},
    // file icons set
    icons: {type: Object, default: () => fileIcons},
    // custom backend endpoints
    // endpoints: { type: Object, default: () => endpoints },
    // custom axios instance
    // axios: { type: Function },
    // custom configuration for internal axios instance
    // axiosConfig: { type: Object, default: () => {} },
    // max files count to upload at once. Unlimited by default
    maxUploadFilesCount: {type: Number, default: 0},
    // max file size to upload. Unlimited by default
    maxUploadFileSize: {type: Number, default: 0}
  },
  data() {
    return {
      loading: 0,
      path: "/",
      activeStorage: null,
      uploadingFiles: false, // or an Array of files
      refreshPending: false,
      defaultFilesArray: [],
      filesArray: [],
      defaultFoldersArray: [
        {
          type: "dir",
          path: "/",
          basename: `root`,
          extension: "",
          name: "root",
          children: []
        }],
      foldersArray: [
        {
          type: "dir",
          path: "/",
          basename: "root",
          extension: "",
          name: "root",
          children: []
        }]
    };
  },
  computed: {
    storagesArray() {
      let storageCodes = this.storages.split(","),
          result = [];
      storageCodes.forEach(code => {
        result.push(availableStorages.find(item => item.code === code));
      });
      return result;
    }
  },
  methods: {
    loadingChanged(loading) {
      if (loading) {
        this.loading++;
      } else if (this.loading > 0) {
        this.loading--;
      }
    },
    storageChanged(storage) {
      this.activeStorage = storage;
    },
    addUploadingFiles(files) {
      files = Array.from(files);
      if (this.maxUploadFileSize) {
        files = files.filter(
            file => file.size <= this.maxUploadFileSize
        );
      }
      if (this.uploadingFiles === false) {
        this.uploadingFiles = [];
      }

      if (this.maxUploadFilesCount && this.uploadingFiles.length + files.length > this.maxUploadFilesCount) {
        files = files.slice(0, this.maxUploadFilesCount - this.uploadingFiles.length);
      }
      this.uploadingFiles.push(...files);
    },
    removeUploadingFile(index) {
      this.uploadingFiles.splice(index, 1);
    },
    uploaded() {
      this.uploadingFiles = false;
      this.refreshPending = true;
    },
    async pathChanged(path) {
      this.path = path;
      // console.log(path)
      await this.load()
      // this.$emit("change", path);
    },

    addDirectories(array, currentPath) {
      return array.map(item => {
        return {
          type: "dir",
          path: item.Prefix,
          basename: removeTrailingSlash(item.Prefix).replace(currentPath, ''),
          extension: "",
          name: removeTrailingSlash(item.Prefix).replace(currentPath, ''),
          children: []
        }
      });
    },

    async load() {
      this.$emit("loading", true);
      if (this.group.length > 3) {
        this.foldersArray = []
        this.filesArray = this.defaultFilesArray
        try {
          let s3FolderContents = await StorageHttp.list({
            path: removeLeadingSlash(this.path),
            storage: "s3",
            majorFolder: `myflyingmanager-${this.group}`
          }).then(result => result.data);
          this.foldersArray = []
          if (s3FolderContents.CommonPrefixes) {
            this.foldersArray = this.addDirectories(s3FolderContents.CommonPrefixes, s3FolderContents.Prefix)
            // console.log(this.foldersArray)
          }
          this.filesArray = []
          if (s3FolderContents.Contents) {
            this.filesArray = s3FolderContents.Contents.map(item => {
              return {
                type: "file",
                key: item.Key,
                basename: removeTrailingSlash(item.Key).replace(s3FolderContents.Prefix, ''),
                extension: getFileExtension(item.Key),
                name: removeTrailingSlash(item.Key).replace(s3FolderContents.Prefix, ''),
                size: item.Size,
              }
            })
          }
        } catch (e) {
          this.foldersArray = [
            {
              type: "dir",
              path: "/",
              basename: `No File area exists for the selected group.`,
              extension: "",
              name: "No File area exists",
              children: []
            }],
          this.filesArray = []
        }
      }
      // this.$emit("loading", false);
    },

    async download(key) {
      const url = await StorageHttp.download({bucket: `myflyingmanager-${this.group}`, key: key})
      window.open(url.data);
    }
  },
  created() {
    this.activeStorage = this.storage;
  },
  mounted() {
    this.load()
    // if (!this.path && !(this.tree && this.$vuetify.breakpoint.smAndUp)) {
    //   this.pathChanged("/");
    // }
  },
  watch: {
    group: {
      handler() {
        this.load()
      },
      immediate: true
    }
  }
};
</script>

<style lang="scss" scoped>
</style>