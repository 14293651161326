<script>
export default {
  name: "HomeFlightCard",
  props: {
    stats: {}
  },
  data () {
    return {
    panel: [0],
    activeTab: "time",
    info : [{text: "Last 28 Days", time: "100:00", flights: "1", landings: ""},
      {text: "Last 60 Days", time: "100:00", flights: "2", landings: ""},
      {text: "Last 90 Days", time: "100:00", flights: "3", landings: ""},
      {text: "Last 12 Months", time: "100:00", flights: "4", landings: ""},
      {text: "Last 24 Months", time: "100:00", flights: "5", landings: ""},
      {text: "This Year (2023)", time: "100:00", flights: "1000", landings: ""},
      {text: "All", time: "100000:00", flights: "100000", landings: "1000000"}]
  }},
  methods: {

  }
};
</script>


<template>
  <v-sheet class="my-2">
    <v-expansion-panels v-model="panel" mandatory>
      <v-expansion-panel >
        <v-expansion-panel-header>
          <h2>
            <v-icon icon="mdi-airplane" class="mx-2"/>
            Flying
          </h2>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
        <v-tabs
          fixed-tabs
          bg-color="surface"
          v-model="activeTab"
        >
          <v-tab value="time">
            Flying Time
          </v-tab>
          <v-tab value="flights">
            Flights
          </v-tab>
          <v-tab value="landings">
            Landings
          </v-tab>
          </v-tabs>
          <v-tabs-items v-model="activeTab">
            <v-tab-item key="time">
              <v-simple-table density="compact">
                <thead>
                </thead>
                <tbody>
                <tr v-for="(i,j) in stats" :key="j">
                  <td>{{ i.text }}:</td>
                  <td class="text-right">{{ i.time }}
                  </td>
                </tr>
                </tbody>
              </v-simple-table>
            </v-tab-item>
            <v-tab-item key="flights">
              <v-simple-table density="compact">
                <thead>
                </thead>
                <tbody>
                <tr v-for="i in stats" :key="i.text">
                  <td>{{ i.text }}:</td>
                  <td class="text-right">{{ i.flights }}
                  </td>
                </tr>
                </tbody>
              </v-simple-table>
            </v-tab-item>
            <v-tab-item key="landings">
              <v-simple-table density="compact">
                <thead>
                </thead>
                <tbody>
                <tr v-for="i in stats" :key="i.text">
                  <td>{{ i.text }}:</td>
                  <td class="text-right">{{ i.landings }}
                  </td>
                </tr>
                </tbody>
              </v-simple-table>
            </v-tab-item>
          </v-tabs-items>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-sheet>
</template>

<style scoped>

</style>