<template>
<my-dashboard />
</template>

<script>
import MyDashboard from "@/components/shared/MyDashboard";
export default {
name: "MyDashboardPage",
  components: {MyDashboard}
}
</script>

<style scoped>

</style>