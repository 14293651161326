import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VSheet,{staticClass:"my-2"},[_c(VExpansionPanels,{attrs:{"mandatory":""},model:{value:(_vm.panel),callback:function ($$v) {_vm.panel=$$v},expression:"panel"}},[_c(VExpansionPanel,[_c(VExpansionPanelHeader,[_c('h2',[_c(VIcon,{staticClass:"mx-2",attrs:{"icon":"mdi-airplane"}}),_vm._v(" Flying ")],1)]),_c(VExpansionPanelContent,[_c(VTabs,{attrs:{"fixed-tabs":"","bg-color":"surface"},model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[_c(VTab,{attrs:{"value":"time"}},[_vm._v(" Flying Time ")]),_c(VTab,{attrs:{"value":"flights"}},[_vm._v(" Flights ")]),_c(VTab,{attrs:{"value":"landings"}},[_vm._v(" Landings ")])],1),_c(VTabsItems,{model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[_c(VTabItem,{key:"time"},[_c(VSimpleTable,{attrs:{"density":"compact"}},[_c('thead'),_c('tbody',_vm._l((_vm.stats),function(i,j){return _c('tr',{key:j},[_c('td',[_vm._v(_vm._s(i.text)+":")]),_c('td',{staticClass:"text-right"},[_vm._v(_vm._s(i.time)+" ")])])}),0)])],1),_c(VTabItem,{key:"flights"},[_c(VSimpleTable,{attrs:{"density":"compact"}},[_c('thead'),_c('tbody',_vm._l((_vm.stats),function(i){return _c('tr',{key:i.text},[_c('td',[_vm._v(_vm._s(i.text)+":")]),_c('td',{staticClass:"text-right"},[_vm._v(_vm._s(i.flights)+" ")])])}),0)])],1),_c(VTabItem,{key:"landings"},[_c(VSimpleTable,{attrs:{"density":"compact"}},[_c('thead'),_c('tbody',_vm._l((_vm.stats),function(i){return _c('tr',{key:i.text},[_c('td',[_vm._v(_vm._s(i.text)+":")]),_c('td',{staticClass:"text-right"},[_vm._v(_vm._s(i.landings)+" ")])])}),0)])],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }