import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"transition":"dialog-top-transition","max-width":"600","value":true},scopedSlots:_vm._u([{key:"default",fn:function(dialog){return [_c(VCard,[_c(VToolbar,{attrs:{"color":"primary","dark":""}},[_vm._v("Opening from the top ")]),_c(VCardText,[(!_vm.url)?_c(VProgressCircular,{attrs:{"indeterminate":"","color":"primary"}}):_vm._e(),_c('div',{staticClass:"text-h2 pa-12"},[_vm._v("Hello world!")]),_vm._v(" "+_vm._s(_vm.objectKey)+" ")],1),_c(VCardActions,{staticClass:"justify-end"},[_c(VBtn,{attrs:{"text":""},on:{"click":function($event){dialog.value = false}}},[_vm._v("Close ")])],1)],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }