<template>
  <v-container class="my-5">
    <v-row>
      <HomeFlightCard :stats="sortieStats" />
    </v-row>
  </v-container>
</template>

<script>
import MaterialStatCard from "@/components/shared/MaterialStatsCard";
import {mapState, mapActions } from 'vuex'
import HomeFlightCard from "@/components/home/HomeFlightCard.vue";

export default {
  name: "MyDashboard",
  components: {
    HomeFlightCard,
    MaterialStatCard,
  },
  computed:{
    ...mapState("sorties", ["sortieStats"]),
    ...mapState("app",["loggedInUser"])
  },
  data() {
    return {
      selectedItem: 0,
      flightStats:[
        {
          icon: "mdi-clock-outline",
          color: "primary",
          title: "Flying Time",
          period: [
            {
              periodName: "Last 28 Days",
              periodValue: "00:00"
            },
            {
              periodName:"Last 60 Days",
              periodValue: "00:00"
            },
            {
              periodName:"Last 90 Days",
              periodValue: "00:00"
            },
          ]
        },
        {
          icon: "mdi-airplane",
          color: "primary",
          title: "Flights",
          period: [
            {
              periodName:"Last 28 Days",
              periodValue:0
            },
            {
              periodName:"Last 60 Days",
              periodValue:0
            },
            {
              periodName:"Last 90 Days",
              periodValue:0
            },
          ]
        },
        {
          icon: "mdi-airplane-landing",
          color: "primary",
          title: "Landings",
          period: [
            {
              periodName:"Last 28 Days",
              periodValue:0
            },
            {
              periodName:"Last 60 Days",
              periodValue:0
            },
            {
              periodName:"Last 90 Days",
              periodValue:0
            },
          ]
        },
      ]
    }
  },
  async mounted() {
    await this.setSortieStats(this.loggedInUser._id)
  },
  methods: {
    ...mapActions("sorties", ["setSortieStats"]),
    sortBy(prop){
      this.sorties.sort((a,b)=> a[prop] < b[prop] ? -1 : 1)
    }
  }
};
</script>